import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {request} from "@utils/network";
import {Breadcrumb} from "antd";
import {FMsg} from "@components/formatted";
import baseStore from "@store/base";
import {parseI18nDataToObj} from "@utils/tools";
import {observer} from "mobx-react";
import {equals} from "ramda"

function Index(props) {

  const params = useParams();

  const dep = useRef({});
  const sourceData = useRef({});
  const rid = useRef(params.rid);

  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);

  const getRandom = () => {
    request("yqf/randomProductList", {ranNum: 3}).then(res => {
      setList([...(res.data.list || [])])
    })
  }

  const getData = () => {
    request("yqf/productDetail", {id: params.rid}).then(res => {
      const data = parseI18nDataToObj({arr: res.data || [], language: baseStore.languageArr});
      sourceData.current = data;
      setInfo({...data[baseStore.language]})
    })
  }

  useEffect(() => {
    getData();
    getRandom();
    dep.current = {params, language: baseStore.language};
  }, [])

  useEffect(() => {
    if (rid.current !== params.rid) {
      getData();
      getRandom();
      rid.current = params.rid;
      return
    }
    if (!equals(dep.current, {params, language: baseStore.language})) {
      setInfo({...sourceData.current[baseStore.language]});
      dep.current = {params, language: baseStore.language};
      getRandom();
    }
  }, [params, baseStore.language])

  return (
    <div className="main">
      <div className="left_right">
        <div className="left_box">
          <span className="left_tit"><i><FMsg id="product.base.rec"/></i></span>
          <ul className="tj_pro">
            {
              list.map((value, index) => (
                <li key={index}>
                  <Link to={`/productcontent/${value.id}/${value.realProductId}`}>
                    <span className="index_pro_imgl ov-he"
                          style={{background: `url(${value.productImg}) no-repeat center / cover`}}/>
                    <p>{value.productName}</p>
                  </Link>
                </li>
              ))
            }
          </ul>
          <span className="left_tit"><i><FMsg id="about.base.contact"/></i></span>
          <ul className="list">
            <li><span><FMsg id="about.base.address"/>:</span><em>{baseStore.currentInfo.address || "-"}</em></li>
            <li><span><FMsg id="about.base.tel"/>:</span><em>{baseStore.currentInfo.hottel || "-"}</em></li>
            <li><span><FMsg id="about.base.fax"/>:</span><em>{baseStore.currentInfo.cz || "-"}</em></li>
            <li><span><FMsg id="about.base.email"/>:</span><em>{baseStore.currentInfo.email || "-"}</em></li>
          </ul>
        </div>
        <div className="right_box">
          <div className="crumb_container">
            <Breadcrumb>
              <Breadcrumb.Item href="/"><FMsg id="index.bar.home"/></Breadcrumb.Item>
              <Breadcrumb.Item href="/product"><FMsg id="index.bar.product"/></Breadcrumb.Item>
              <Breadcrumb.Item><FMsg id="product.base.detail"/></Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="news_content">
            <div className="news_content_tit"><h1>{info.productName || ""}</h1></div>
            <div className="index_news_time"><i>{info.createTime || ""}</i></div>
          </div>
          <div className="product_text">
            <img src={info.productImg || ""} alt={info.productName || ""}/>
            <div dangerouslySetInnerHTML={{__html: info.productIntroduce || ""}}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);