import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../assets/img/logo.png"
import img1 from "../../assets/img/111.jpg";
import {Carousel, Select} from "antd";
import "./index.less";
import {observer} from "mobx-react";
import baseStore from "@store/base";
import {FMsg} from "@components/formatted";
import {request} from "@utils/network";
import {parseI18nDataToObj} from "@utils/tools";

function Header() {

  const [info, setInfo] = useState({});
  const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    const data = parseI18nDataToObj({arr: baseStore.webInfo, language: languageList});
    if (baseStore.language) {
      baseStore.currentInfo = {...data[baseStore.language]};
      setInfo(data[baseStore.language] || {})
    }
  }, [baseStore.webInfo, languageList, baseStore.language]);

  useEffect(() => {
    request("yqf/getLanguage").then(res => {
      if (res.code === 1) {
        baseStore.languageArr = [...(res.data.list || [])]
        setLanguageList(res.data.list || [])
      }
    })
  }, [])

  useEffect(() => {
    // 更新网站关键词，描述，名字
    if (Object.keys(info).length > 0) {
      const title = document.head.getElementsByTagName("title");
      const metas = document.head.getElementsByTagName("meta");
      for (const meta of metas) {
        if (meta.name === "keywords") {
          meta.content = info.keyword
        }
        if (meta.name === "description") {
          meta.content = info.webmsg
        }
      }
      title[0].innerText = info.name || "";
    }
  }, [info])

  const onLocalesChange = k => {
    baseStore.language = k;
    const find = languageList.find(v => v.code === k);
    if (find) {
      localStorage.setItem("local", JSON.stringify({code: k, id: find.id}));
      baseStore.languageId = find.id;
    }
  }

  return (
    <header>
      <div className="header">
        <div className="head_left">
          <img src={logo} alt="logo"/>
          <span>
            <b>
              <FMsg id="index.name" values={{name: info.name || "NINGBO YIQIFA  TECHNOLOGYCO,LTD"}}/></b>
            <i>NINGBO YIQIFA  TECHNOLOGYCO,LTD</i>
          </span>
        </div>
        <div className="head_right">
          <ul className="head_nav">
            <li><NavLink to="/"><FMsg id="index.bar.home"/></NavLink></li>
            <li><NavLink to="/aboutus"><FMsg id="index.bar.about"/></NavLink></li>
            <li><NavLink to="/product"><FMsg id="index.bar.product"/></NavLink></li>
            <li><NavLink to="/news"><FMsg id="index.bar.news"/></NavLink></li>
            <li><NavLink to="/contactus"><FMsg id="index.bar.contact"/></NavLink></li>
            {/*<li><NavLink to="/joinus">加入我们</NavLink></li>*/}
          </ul>
          <Select value={baseStore.language} style={{width: 150, marginLeft: 15}} onChange={onLocalesChange}>
            {
              languageList.map((value) => (
                <Select.Option key={value.id} value={value.code}>{value.realName}</Select.Option>
              ))
            }
          </Select>
        </div>
      </div>
      <div className="lbimg">
        <Carousel autoplay={false} dots={false}>
          <div>
            <div className="header_carousel_img" style={{backgroundImage: `url(${img1})`}}/>
          </div>
        </Carousel>
      </div>
    </header>
  );
}

export default observer(Header);