/**
 * 把后端返回的数据（数组）转换为语言对象
 * @param params {Object} 参数对象
 * @param params.arr {[*]} 数据
 * @param params.language {[*]} 语言列表
 * @return {{}}
 * @example
 * {
 *  zh_CN: {a: 1},
 *  en_US: {b: 2}
 * }
 */
export function parseI18nDataToObj(params) {
  const {arr, language} = params;
  let obj = {};
  for (const item of language) {
    const key = obj[item.code];
    if (!key) {
      obj[item.code] = {};
    }
    for (const data of arr) {
      if (data.languageId === item.id) {
        obj[item.code] = {...data};
      }
    }
  }
  return obj
}