import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import viewImg from "@assets/img/view.png"
import {request} from "@utils/network";
import {Breadcrumb} from "antd";
import {FMsg} from "@components/formatted";
import baseStore from "@store/base";
import {equals} from "ramda";
import {observer} from "mobx-react";
import {parseI18nDataToObj} from "@utils/tools";

function Index(props) {

  const params = useParams();

  const rid = useRef(params.rid);
  const dep = useRef({});

  const [info, setInfo] = useState({});
  const [preview, setPreview] = useState({prev: undefined, next: undefined})

  const getData = () => {
    request("yqf/newsPrevNext", {id: params.rid}).then(res => {
      const {next, prev} = res.data;
      setPreview({
        next: next[0] || undefined,
        prev: prev[0] || undefined,
      })
    })
    request("yqf/newDetail", {id: params.rid}).then(res => {
      const data = parseI18nDataToObj({arr: res.data || [], language: baseStore.languageArr});
      setInfo({...data[baseStore.language]})
    })
  }

  useEffect(() => {
    getData();
    dep.current = {params, language: baseStore.language};
    // request("yqf/updateCount", {id: params.rid});
  }, [])

  useEffect(() => {
    if (rid.current !== params.rid) {
      getData();
      rid.current = params.rid;
      return
    }
    if (!equals(dep.current, {params, language: baseStore.language})) {
      getData();
      dep.current = {params, language: baseStore.language};
    }
  }, [params, baseStore.language])

  return (
    <div className="main">
      <div className="left_right">
        <div className="left_box">
          <span className="left_tit"><i><FMsg id="index.middle.nt1"/></i></span>
          <div className="link">
            <Link to="/news"><FMsg id="index.footer.news"/></Link>
          </div>
          <span className="left_tit"><i><FMsg id="about.base.contact"/></i></span>
          <ul className="list">
            <li><span><FMsg id="about.base.address"/>:</span><em>{baseStore.currentInfo.address || "-"}</em></li>
            <li><span><FMsg id="about.base.tel"/>:</span><em>{baseStore.currentInfo.hottel || "-"}</em></li>
            <li><span><FMsg id="about.base.fax"/>:</span><em>{baseStore.currentInfo.cz || "-"}</em></li>
            <li><span><FMsg id="about.base.email"/>:</span><em>{baseStore.currentInfo.email || "-"}</em></li>
          </ul>
        </div>
        <div className="right_box">
          <div className="crumb_container">
            <Breadcrumb>
              <Breadcrumb.Item href="/"><FMsg id="index.bar.home"/></Breadcrumb.Item>
              <Breadcrumb.Item href="/news"><FMsg id="index.bar.news"/></Breadcrumb.Item>
              <Breadcrumb.Item><FMsg id="news.base.detail"/></Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="news_content">
            <div className="news_content_tit">
              <h1>{info.newsName}</h1>
              <div className="index_news_time"><i>{info.newsCreatetime}</i>
                <img src={viewImg} alt="eyes"/><em>{info.views}</em>
              </div>
            </div>
            <div className="news_text" dangerouslySetInnerHTML={{__html: info.newsContent}}/>
            <div className="page_up_down">
              {
                preview.prev
                  ? <Link to={`/newscontent/${preview.prev.id}/${preview.prev.realNewsId}`}
                          title={preview.prev.newsName}><FMsg id="news.base.prev"/>：{preview.prev.newsName}</Link>
                  : null
              }
              {
                preview.next
                  ? <Link to={`/newscontent/${preview.next.id}/${preview.next.realNewsId}`}
                          title={preview.next.newsName}><FMsg id="news.base.next"/>：{preview.next.newsName}</Link>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);