import LOCALES from "@/i18n/locales";

const messages = {
  [LOCALES.ENGLISH]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "Record Number",
    "index.middle.year": "Year of incorporation",
    "index.middle.employee": "Employee over",
    "index.middle.served": "Served customers",
    "index.middle.h1": "Walk steadily and strive for excellence",
    "index.middle.t1": "Quality Control",
    "index.middle.st1": "With consistent excellent quality, assist customers to maximize value.",
    "index.middle.t2": "Technology research and development",
    "index.middle.st2": "Technology has only a starting point, innovation has no end point, and science and technology makes the industry",
    "index.middle.t3": "Continuous innovation",
    "index.middle.st3": "Leading the new trend of the world bearing industry with innovation and Transcendence",
    "index.middle.pt1": "Product display",
    "index.middle.pt2": "Consistently strive for excellence",
    "index.middle.nt1": "News information",
    "index.middle.nt2": "Always pay attention to industry trends",
    "index.viewMore": "more",
    "index.middle.all": "ALL",
    "index.middle.childTit1": "Year",
    "index.middle.childTit2": "S",
    "index.middle.childTit3": "S",
    // 导航
    "index.bar.home": "Home",
    "index.bar.about": "About",
    "index.bar.product": "Product",
    "index.bar.news": "News",
    "index.bar.contact": "Contact",
    // 底部
    "index.footer.profile": "company profile",
    "index.footer.culture": "corporate culture",
    "index.footer.contact": "Contact us",
    "index.footer.news": "company news",
    "index.footer.industry": "industry news",
    // 产品分类
    "index.product.painting": "painting equipment",
    "index.product.iron": "iron craft fence",
    "index.product.smart": "smart equipment",
    // 关于我们页面
    "about.base.title": "About us",
    "about.base.desc": "Company profile",
    "about.base.culture": "Corporate culture",
    "about.base.contact": "Contact information",
    "about.base.address": "Address",
    "about.base.tel": "Telephone",
    "about.base.fax": "Fax",
    "about.base.email": "Email",
    // 新闻详情页
    "news.base.detail": "News detail",
    "news.base.prev": "Prev",
    "news.base.next": "Next",
    // 产品详情页
    "product.base.rec": "Product recommendation",
    "product.base.detail": "Product Detail",
    // 404页面
    "page.notMatch": "Page Not Found",
    "page.notMatch.recommend": "Recommend products for you",
  },
  [LOCALES.CHINESE]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "备案号",
    "index.middle.year": "总公司成立",
    "index.middle.employee": "员工超过",
    "index.middle.served": "服务客户",
    "index.middle.h1": "行稳致远 精益求精",
    "index.middle.t1": "质量控制",
    "index.middle.st1": "以始终如一的卓越品质，助理客户实现价值最大化。",
    "index.middle.t2": "技术研发",
    "index.middle.st2": "技术只有起点 创新没有终点，科技成就产业",
    "index.middle.t3": "不断创新",
    "index.middle.st3": "用创新与超越领跑着世界轴承行业的新风向",
    "index.middle.pt1": "产品展示",
    "index.middle.pt2": "始终如一 精益求精",
    "index.middle.nt1": "新闻资讯",
    "index.middle.nt2": "时刻关注行业动态",
    "index.viewMore": "查看更多",
    "index.middle.childTit1": "年",
    "index.middle.childTit2": "位",
    "index.middle.childTit3": "家",
    "index.middle.all": "全部",
    // 导航
    "index.bar.home": "首 页",
    "index.bar.about": "关于我们",
    "index.bar.product": "产品中心",
    "index.bar.news": "新闻动态",
    "index.bar.contact": "联系我们",
    // 底部
    "index.footer.profile": "公司简介",
    "index.footer.culture": "企业文化",
    "index.footer.contact": "联系我们",
    "index.footer.news": "公司新闻",
    "index.footer.industry": "行业动态",
    // 产品分类
    "index.product.painting": "涂装设备",
    "index.product.iron": "护栏铁艺",
    "index.product.smart": "智能设备",
    // 关于我们页面
    "about.base.title": "关于我们",
    "about.base.desc": "公司简介",
    "about.base.culture": "企业文化",
    "about.base.contact": "联系方式",
    "about.base.address": "地 址",
    "about.base.tel": "电 话",
    "about.base.fax": "传 真",
    "about.base.email": "邮箱",
    // 新闻详情页
    "news.base.detail": "新闻详情",
    "news.base.prev": "上一页",
    "news.base.next": "下一页",
    // 产品详情页
    "product.base.rec": "推荐产品",
    "product.base.detail": "产品详情",
    // 404页面
    "page.notMatch": "页面丢失了",
    "page.notMatch.recommend": "为您推荐产品",
  },
  [LOCALES.CHINESE_HK]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "備案號",
    "index.middle.year": "總公司成立",
    "index.middle.employee": "員工超過",
    "index.middle.served": "服務客戶",
    "index.middle.h1": "行穩致遠 精益求精",
    "index.middle.t1": "品質控制",
    "index.middle.st1": "以始終如一的卓越品質，助理客戶實現價值最大化。",
    "index.middle.t2": "技術研發",
    "index.middle.st2": "科技只有起點創新沒有終點，科技成就產業",
    "index.middle.t3": "不斷創新",
    "index.middle.st3": "用創新與超越領跑著世界軸承行業的新風向",
    "index.middle.pt1": "產品展示",
    "index.middle.pt2": "始終如一 精益求精",
    "index.middle.nt1": "新聞資訊",
    "index.middle.nt2": "時刻關注行業動態",
    "index.viewMore": "查看更多",
    "index.middle.childTit1": "年",
    "index.middle.childTit2": "位",
    "index.middle.childTit3": "家",
    "index.middle.all": "全部",
    // 导航
    "index.bar.home": "首 頁",
    "index.bar.about": "關於我們",
    "index.bar.product": "產品中心",
    "index.bar.news": "新聞動態",
    "index.bar.contact": "聯繫我們",
    // 底部
    "index.footer.profile": "公司簡介",
    "index.footer.culture": "企業文化",
    "index.footer.contact": "聯繫我們",
    "index.footer.news": "公司新聞",
    "index.footer.industry": "行業動態",
    // 产品分类
    "index.product.painting": "塗裝設備",
    "index.product.iron": "護欄鐵藝",
    "index.product.smart": "智慧設備",
    // 关于我们页面
    "about.base.title": "關於我們",
    "about.base.desc": "公司簡介",
    "about.base.culture": "企業文化",
    "about.base.contact": "聯繫方式",
    "about.base.address": "地 址",
    "about.base.tel": "電 話",
    "about.base.fax": "傳 真",
    "about.base.email": "郵 箱",
    // 新闻详情页
    "news.base.detail": "新聞詳情",
    "news.base.prev": "上一頁",
    "news.base.next": "下一頁",
    // 产品详情页
    "product.base.rec": "推薦產品",
    "product.base.detail": "產品詳情",
    // 404页面
    "page.notMatch": "頁面未找到",
    "page.notMatch.recommend": "為您推薦產品",
  },
  [LOCALES.ENGLISH_GB]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "Record Number",
    "index.middle.year": "Year of incorporation",
    "index.middle.employee": "Employee over",
    "index.middle.served": "Served customers",
    "index.middle.h1": "Walk steadily and strive for excellence",
    "index.middle.t1": "Quality Control",
    "index.middle.st1": "With consistent excellent quality, assist customers to maximize value.",
    "index.middle.t2": "Technology research and development",
    "index.middle.st2": "Technology has only a starting point, innovation has no end point, and science and technology makes the industry",
    "index.middle.t3": "Continuous innovation",
    "index.middle.st3": "Leading the new trend of the world bearing industry with innovation and Transcendence",
    "index.middle.pt1": "Product display",
    "index.middle.pt2": "Consistently strive for excellence",
    "index.middle.nt1": "News information",
    "index.middle.nt2": "Always pay attention to industry trends",
    "index.viewMore": "more",
    "index.middle.all": "ALL",
    "index.middle.childTit1": "Year",
    "index.middle.childTit2": "S",
    "index.middle.childTit3": "S",
    // 导航
    "index.bar.home": "Home",
    "index.bar.about": "About",
    "index.bar.product": "Product",
    "index.bar.news": "News",
    "index.bar.contact": "Contact",
    // 底部
    "index.footer.profile": "company profile",
    "index.footer.culture": "corporate culture",
    "index.footer.contact": "Contact us",
    "index.footer.news": "company news",
    "index.footer.industry": "industry news",
    // 产品分类
    "index.product.painting": "painting equipment",
    "index.product.iron": "iron craft fence",
    "index.product.smart": "smart equipment",
    // 关于我们页面
    "about.base.title": "About us",
    "about.base.desc": "Company profile",
    "about.base.culture": "Corporate culture",
    "about.base.contact": "Contact information",
    "about.base.address": "Address",
    "about.base.tel": "Telephone",
    "about.base.fax": "Fax",
    "about.base.email": "Email",
    // 新闻详情页
    "news.base.detail": "News detail",
    "news.base.prev": "Prev",
    "news.base.next": "Next",
    // 产品详情页
    "product.base.rec": "Product recommendation",
    "product.base.detail": "Product Detail",
    // 404页面
    "page.notMatch": "Page Not Found",
    "page.notMatch.recommend": "Recommend products for you",
  },
  [LOCALES.CHINESE_TW]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "備案號",
    "index.middle.year": "總公司成立",
    "index.middle.employee": "員工超過",
    "index.middle.served": "服務客戶",
    "index.middle.h1": "行穩致遠 精益求精",
    "index.middle.t1": "品質控制",
    "index.middle.st1": "以始終如一的卓越品質，助理客戶實現價值最大化。",
    "index.middle.t2": "技術研發",
    "index.middle.st2": "科技只有起點創新沒有終點，科技成就產業",
    "index.middle.t3": "不斷創新",
    "index.middle.st3": "用創新與超越領跑著世界軸承行業的新風向",
    "index.middle.pt1": "產品展示",
    "index.middle.pt2": "始終如一 精益求精",
    "index.middle.nt1": "新聞資訊",
    "index.middle.nt2": "時刻關注行業動態",
    "index.viewMore": "查看更多",
    "index.middle.childTit1": "年",
    "index.middle.childTit2": "位",
    "index.middle.childTit3": "家",
    "index.middle.all": "全部",
    // 导航
    "index.bar.home": "首 頁",
    "index.bar.about": "關於我們",
    "index.bar.product": "產品中心",
    "index.bar.news": "新聞動態",
    "index.bar.contact": "聯繫我們",
    // 底部
    "index.footer.profile": "公司簡介",
    "index.footer.culture": "企業文化",
    "index.footer.contact": "聯繫我們",
    "index.footer.news": "公司新聞",
    "index.footer.industry": "行業動態",
    // 产品分类
    "index.product.painting": "塗裝設備",
    "index.product.iron": "護欄鐵藝",
    "index.product.smart": "智慧設備",
    // 关于我们页面
    "about.base.title": "關於我們",
    "about.base.desc": "公司簡介",
    "about.base.culture": "企業文化",
    "about.base.contact": "聯繫方式",
    "about.base.address": "地 址",
    "about.base.tel": "電 話",
    "about.base.fax": "傳 真",
    "about.base.email": "郵 箱",
    // 新闻详情页
    "news.base.detail": "新聞詳情",
    "news.base.prev": "上一頁",
    "news.base.next": "下一頁",
    // 产品详情页
    "product.base.rec": "推薦產品",
    "product.base.detail": "產品詳情",
    // 404页面
    "page.notMatch": "頁面未找到",
    "page.notMatch.recommend": "為您推薦產品",
  },
  [LOCALES.KOREAN]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "등록 번호",
    "index.middle.year": "본사 설립 총연도",
    "index.middle.employee": "전체 직원 수",
    "index.middle.served": "서비스 고객 수",
    "index.middle.h1": "더욱 향상된 성능",
    "index.middle.t1": "품질 제어",
    "index.middle.st1": "고객의 가치를 극대화하는 데 도움이 되는 일관된 품질",
    "index.middle.t2": "기술 연구 개발",
    "index.middle.st2": "기술은 기점 혁신만 있고 종점은 없고 과학 기술은 산업을 이룬다",
    "index.middle.t3": "끊임없이 혁신하다.",
    "index.middle.st3": "혁신과 초월로 세계 베어링 업계의 새로운 풍향을 선도하고 있다",
    "index.middle.pt1": "제품 전시",
    "index.middle.pt2": "시종일관 한결같다--더욱 정교해지다",
    "index.middle.nt1": "뉴스 정보",
    "index.middle.nt2": "업계 동향 파악",
    "index.viewMore": "자세히 보기",
    "index.middle.childTit1": "년",
    "index.middle.childTit2": "비트",
    "index.middle.childTit3": "집.",
    "index.middle.all": "모조리",
    // 导航
    "index.bar.home": "첫 페이지",
    "index.bar.about": "우리",
    "index.bar.product": "제품 센터",
    "index.bar.news": "뉴스 동향",
    "index.bar.contact": "연락처",
    // 底部
    "index.footer.profile": "회사 소개",
    "index.footer.culture": "기업 문화",
    "index.footer.contact": "연락처",
    "index.footer.news": "회사 뉴스",
    "index.footer.industry": "업계 동향",
    // 产品分类
    "index.product.painting": "도장 설비",
    "index.product.iron": "가드레일",
    "index.product.smart": "지능 설비",
    // 关于我们页面
    "about.base.title": "우리",
    "about.base.desc": "회사 소개",
    "about.base.culture": "기업 문화",
    "about.base.contact": "연락처",
    "about.base.address": "소재지",
    "about.base.tel": "전화기",
    "about.base.fax": "팩시밀리",
    "about.base.email": "우편함",
    // 新闻详情页
    "news.base.detail": "뉴스 상세 정보",
    "news.base.prev": "이전 페이지",
    "news.base.next": "다음 페이지",
    // 产品详情页
    "product.base.rec": "추천 제품",
    "product.base.detail": "제품 상세 정보",
    // 404页面
    "page.notMatch": "페이지 분실",
    "page.notMatch.recommend": "제품 추천",
  },
  [LOCALES.JAPANESE]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "届出番号",
    "index.middle.year": "本社設立総年",
    "index.middle.employee": "従業員合計",
    "index.middle.served": "サービス顧客数",
    "index.middle.h1": "安定して遠くまで行く--精進して精進する",
    "index.middle.t1": "しつりょうせいぎょ",
    "index.middle.st1": "一貫した優れた品質で、お客様の価値を最大化",
    "index.middle.t2": "技術研究開発",
    "index.middle.st2": "技術は起点の革新だけで終点がなく、科学技術は産業を成し遂げる。",
    "index.middle.t3": "絶えず革新する.",
    "index.middle.st3": "革新と超越で世界のベアリング業界をリードする新しい風向",
    "index.middle.pt1": "製品展示",
    "index.middle.pt2": "終始一貫して精進する",
    "index.middle.nt1": "ニュース",
    "index.middle.nt2": "業界の動向に常に注目",
    "index.viewMore": "詳細を表示",
    "index.middle.childTit1": "年",
    "index.middle.childTit2": "ビット",
    "index.middle.childTit3": "家",
    "index.middle.all": "すべて",
    // 导航
    "index.bar.home": "トップページ",
    "index.bar.about": "私たちについて",
    "index.bar.product": "製品センター",
    "index.bar.news": "ニュース",
    "index.bar.contact": "お問い合わせ",
    // 底部
    "index.footer.profile": "会社概要",
    "index.footer.culture": "企業文化",
    "index.footer.contact": "お問い合わせ",
    "index.footer.news": "会社ニュース",
    "index.footer.industry": "業界の動向",
    // 产品分类
    "index.product.painting": "塗装設備",
    "index.product.iron": "ガードレール",
    "index.product.smart": "インテリジェントデバイス",
    // 关于我们页面
    "about.base.title": "私たちについて",
    "about.base.desc": "会社概要",
    "about.base.culture": "企業文化",
    "about.base.contact": "連絡先",
    "about.base.address": "アドレス",
    "about.base.tel": "電話番号",
    "about.base.fax": "ファクシミリ",
    "about.base.email": "メールボックス",
    // 新闻详情页
    "news.base.detail": "ニュースの詳細",
    "news.base.prev": "前のページ",
    "news.base.next": "次のページ",
    // 产品详情页
    "product.base.rec": "推奨製品",
    "product.base.detail": "製品の詳細",
    // 404页面
    "page.notMatch": "ページが失われました",
    "page.notMatch.recommend": "お勧めの製品",
  },
  [LOCALES.FRENCH]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "Numéro de dépôt",
    "index.middle.year": "Année de constitution du siège social",
    "index.middle.employee": "Nombre total d & apos; employés",
    "index.middle.served": "Nombre de clients servis",
    "index.middle.h1": "Aller de l'avant de façon stable - continuer à s'améliorer",
    "index.middle.t1": "Contrôle de la qualité",
    "index.middle.st1": "Maximiser la valeur pour les clients associés avec une qualité supérieure constante",
    "index.middle.t2": "Recherche et développement technologiques",
    "index.middle.st2": "La technologie n'a qu'un point de départ, l'innovation n'a pas de fin, la science et la technologie atteignent l'industrie",
    "index.middle.t3": "Innovation continue",
    "index.middle.st3": "Diriger l'industrie mondiale des roulements avec innovation et transcendance",
    "index.middle.pt1": "Présentation des produits",
    "index.middle.pt2": "Cohérence – Excellence",
    "index.middle.nt1": "Nouvelles",
    "index.middle.nt2": "Suivre l'évolution de l'industrie",
    "index.viewMore": "Voir plus",
    "index.middle.childTit1": "Année",
    "index.middle.childTit2": "S",
    "index.middle.childTit3": "La maison",
    "index.middle.all": "Tous",
    // 导航
    "index.bar.home": "Page d'accueil",
    "index.bar.about": "À propos de nous",
    "index.bar.product": "Centre de produits",
    "index.bar.news": "Faits nouveaux",
    "index.bar.contact": "Contactez - nous",
    // 底部
    "index.footer.profile": "Profil de l'entreprise",
    "index.footer.culture": "Culture d'entreprise",
    "index.footer.contact": "Contactez - nous",
    "index.footer.news": "Nouvelles de l'entreprise",
    "index.footer.industry": "Évolution de l'industrie",
    // 产品分类
    "index.product.painting": "Matériel de peinture",
    "index.product.iron": "Garde - corps en fer",
    "index.product.smart": "Appareils intelligents",
    // 关于我们页面
    "about.base.title": "À propos de nous",
    "about.base.desc": "Profil de l'entreprise",
    "about.base.culture": "Culture d'entreprise",
    "about.base.contact": "Coordonnées",
    "about.base.address": "Adresse",
    "about.base.tel": "Téléphone",
    "about.base.fax": "FAX",
    "about.base.email": "E - mail",
    // 新闻详情页
    "news.base.detail": "Détails de la presse",
    "news.base.prev": "Page précédente",
    "news.base.next": "Page suivante",
    // 产品详情页
    "product.base.rec": "Produits recommandés",
    "product.base.detail": "Détails du produit",
    // 404页面
    "page.notMatch": "Page manquante",
    "page.notMatch.recommend": "Recommander des produits pour vous",
  },
  [LOCALES.GERMAN]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "Datensatznummer",
    "index.middle.year": "Jahr der Gründung des Hauptsitzes",
    "index.middle.employee": "Gesamtzahl der Beschäftigten",
    "index.middle.served": "Anzahl der betreuten Kunden",
    "index.middle.h1": "Geh ruhig und geh weit, verbessere dich weiter",
    "index.middle.t1": "Qualitätskontrolle",
    "index.middle.st1": "Unterstützen Sie Kunden, den Wert mit gleichbleibend ausgezeichneter Qualität zu maximieren",
    "index.middle.t2": "Technologieforschung und -entwicklung",
    "index.middle.st2": "Technologie hat nur einen Ausgangspunkt, Innovation hat keinen Endpunkt, und Wissenschaft und Technologie machen die Industrie",
    "index.middle.t3": "Kontinuierliche Innovation",
    "index.middle.st3": "Führen Sie den neuen Trend der weltweiten Lagerindustrie mit Innovation und Transzendenz",
    "index.middle.pt1": "Produktanzeige",
    "index.middle.pt2": "Konsistenz und Exzellenz",
    "index.middle.nt1": "Neuigkeiten",
    "index.middle.nt2": "Immer auf Branchentrends achten",
    "index.viewMore": "Mehr sehen",
    "index.middle.childTit1": "Jahr",
    "index.middle.childTit2": "Position",
    "index.middle.childTit3": "home",
    "index.middle.all": "ganz",
    // 导航
    "index.bar.home": "Startseite",
    "index.bar.about": "Über uns",
    "index.bar.product": "Produktcenter",
    "index.bar.news": "News Informationen",
    "index.bar.contact": "Kontaktieren Sie uns",
    // 底部
    "index.footer.profile": "Firmenprofil",
    "index.footer.culture": "Unternehmenskultur",
    "index.footer.contact": "Kontaktieren Sie uns",
    "index.footer.news": "Unternehmensnachrichten",
    "index.footer.industry": "Industriedynamik",
    // 产品分类
    "index.product.painting": "Lackieranlagen",
    "index.product.iron": "Geländer Eisen",
    "index.product.smart": "Intelligentes Gerät",
    // 关于我们页面
    "about.base.title": "Über uns",
    "about.base.desc": "Firmenprofil",
    "about.base.culture": "Unternehmenskultur",
    "about.base.contact": "Kontaktinformationen",
    "about.base.address": "Adresse",
    "about.base.tel": "Telefon",
    "about.base.fax": "Fax",
    "about.base.email": "Postfach",
    // 新闻详情页
    "news.base.detail": "News Detail",
    "news.base.prev": "vorherige Seite",
    "news.base.next": "nächste Seite",
    // 产品详情页
    "product.base.rec": "Empfohlene Produkte",
    "product.base.detail": "Produktdetails",
    // 404页面
    "page.notMatch": "Die Seite fehlt",
    "page.notMatch.recommend": "Produkte für Sie empfehlen",
  },
  [LOCALES.RUSSIAN]: {
    // 杂项
    "index.name": "{name}",
    "index.record.name": "регистрационный номер",
    "index.middle.year": "общий год создания",
    "index.middle.employee": "Общее количество работников",
    "index.middle.served": "количество обслуживаемых клиентов",
    "index.middle.h1": "стабильно и далеко",
    "index.middle.t1": "контроль качества",
    "index.middle.st1": "с неизменным выдающимся качеством, помощники клиентов для достижения максимальной стоимости",
    "index.middle.t2": "техническое исследование и разработка",
    "index.middle.st2": "технология есть только начало инноваций, нет конца, научно - технические достижения промышленности",
    "index.middle.t3": "непрерывное новаторство",
    "index.middle.st3": "новаторство и трансцендентность к новым тенденциям в мировой подшипниковой промышленности",
    "index.middle.pt1": "Показ продукции",
    "index.middle.pt2": "последовательный - - стремиться к совершенству",
    "index.middle.nt1": "информация",
    "index.middle.nt2": "постоянно следить за развитием событий в отрасли",
    "index.viewMore": "смотреть больше",
    "index.middle.childTit1": "год",
    "index.middle.childTit2": "разряд",
    "index.middle.childTit3": "жилище",
    "index.middle.all": "все",
    // 导航
    "index.bar.home": "Первая страница",
    "index.bar.about": "о нас",
    "index.bar.product": "продуктовый центр",
    "index.bar.news": "динамика новостей",
    "index.bar.contact": "связаться с нами",
    // 底部
    "index.footer.profile": "компания",
    "index.footer.culture": "культура предприятия",
    "index.footer.contact": "связаться с нами",
    "index.footer.news": "Новости компании",
    "index.footer.industry": "динамика отрасли",
    // 产品分类
    "index.product.painting": "покрытие оборудования",
    "index.product.iron": "ограждение",
    "index.product.smart": "интеллектуальное устройство",
    // 关于我们页面
    "about.base.title": "о нас",
    "about.base.desc": "компания",
    "about.base.culture": "культура предприятия",
    "about.base.contact": "связь",
    "about.base.address": "адрес",
    "about.base.tel": "телефон",
    "about.base.fax": "телефакс",
    "about.base.email": "почтовый ящик",
    // 新闻详情页
    "news.base.detail": "новости",
    "news.base.prev": "Предыдущая страница",
    "news.base.next": "Следующая страница",
    // 产品详情页
    "product.base.rec": "рекомендовать продукцию",
    "product.base.detail": "деталь продукции",
    // 404页面
    "page.notMatch": "Страница утеряна",
    "page.notMatch.recommend": "рекомендовать вам продукцию",
  },
};

export default messages;