const LOCALES = {
  ENGLISH: "en_US",
  ENGLISH_GB: "en_GB",
  CHINESE: "zh_CN",
  CHINESE_TW: "zh_TW",
  CHINESE_HK: "zh_HK",
  KOREAN: "ko_KR",
  JAPANESE: "ja_JP",
  FRENCH: "fr_FR",
  GERMAN: "de_DE",
  RUSSIAN: "ru_RU",
};

export const localesMap = [
  {value: LOCALES.CHINESE, label: "简体中文"},
  {value: LOCALES.CHINESE_TW, label: "繁体中文（台湾地区）"},
  {value: LOCALES.CHINESE_HK, label: "繁体中文（香港地区）"},
  {value: LOCALES.ENGLISH, label: "英语（美国）"},
  {value: LOCALES.ENGLISH_GB, label: "英语（英国）"},
  {value: LOCALES.KOREAN, label: "韩语"},
  {value: LOCALES.JAPANESE, label: "日语"},
  {value: LOCALES.FRENCH, label: "法语"},
  {value: LOCALES.GERMAN, label: "德语"},
  {value: LOCALES.RUSSIAN, label: "俄语"},
];

export default LOCALES;
