import {action, observable} from "mobx";
import {request} from "@utils/network";
import LOCALES from "@/i18n/locales";


class Base {
  @observable
  webInfo = [];

  @observable
  currentInfo = {};

  @observable
  language = LOCALES.CHINESE;

  @observable
  languageId = 1;

  @observable
  languageArr = [];

  /**
   * 获取网站基本信息
   */
  @action
  getWebInfo() {
    request("yqf/webInfo", {}).then(res => {
      if (res.code === 1) {
        this.webInfo = res.data || [];
      }
    })
  }
}

const baseStore = new Base();
export default baseStore;