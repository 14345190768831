import React, {useState} from 'react';
import img1 from "@assets/img/233.jpg";
import {Breadcrumb} from "antd";
import {FMsg} from "@components/formatted";
import {observer} from "mobx-react";
import baseStore from "@store/base";

function Index(props) {

  const [active, setActive] = useState(0);

  const onActive = key => {
    setActive(key);
  }

  return (
    <div className="main">
      <div className="left_right">
        <div className="left_box">
          <span className="left_tit"><i><FMsg id="about.base.title"/></i></span>
          <div className="link">
            <a className={active === 0 ? "jsColor" : ""} onClick={() => onActive(0)}><FMsg id="about.base.desc"/></a>
            <a className={active === 1 ? "jsColor" : ""} onClick={() => onActive(1)}><FMsg id="about.base.culture"/></a>
          </div>
          <span className="left_tit"><i><FMsg id="about.base.contact"/></i></span>
          <ul className="list">
            <li><span><FMsg id="about.base.address"/>:</span><em>{baseStore.currentInfo.address || "-"}</em></li>
            <li><span><FMsg id="about.base.tel"/>:</span><em>{baseStore.currentInfo.hottel || "-"}</em></li>
            <li><span><FMsg id="about.base.fax"/>:</span><em>{baseStore.currentInfo.cz || "-"}</em></li>
            <li><span><FMsg id="about.base.email"/>:</span><em>{baseStore.currentInfo.email || "-"}</em></li>
          </ul>
        </div>
        <div className="right_box">
          <div className="crumb_container">
            <Breadcrumb>
              <Breadcrumb.Item href="/"><i className="layui-icon layui-icon-home"/><FMsg id="index.bar.home"/></Breadcrumb.Item>
              <Breadcrumb.Item href="/aboutus"><FMsg id="index.bar.about"/></Breadcrumb.Item>
              <Breadcrumb.Item><FMsg id="index.footer.profile"/></Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="right_box_content" style={{display: active === 0 ? "block" : "none"}}>
            <img src={img1} alt=""/>
            <div dangerouslySetInnerHTML={{__html: baseStore.currentInfo.companyinfo || ""}} />
          </div>
          <div className="right_box_content" style={{display: active === 1 ? "block" : "none"}}>
            <div dangerouslySetInnerHTML={{__html: baseStore.currentInfo.companyCulture || ""}}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);