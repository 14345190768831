import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

function FMsg(props) {
  return <FormattedMessage {...props}/>;
}
FMsg.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export {
  FMsg
};