import {Route, Routes} from "react-router-dom";
import Index from "./pages/index";
import Header from "./components/header/index";
import "./assets/layui/css/layui.css";
import "./assets/css/animate.min.css";
import "./assets/css/main.css";
import "./App.less";
import Footer from "@components/footer";
import AboutUs from "@pages/aboutus";
import ContactUs from "@pages/contactus";
// import JoinUs from "@pages/joinus";
import News from "@pages/news";
import Product from "@pages/product";
import NewsDetail from "@pages/newsDetail";
import ProductDetail from "@pages/productDetail";
import {useEffect, useRef, useState} from "react";
import baseStore from "@store/base";
import {IntlProvider} from "react-intl";
import {observer} from "mobx-react";
import messages from "@/i18n/messages";
import LOCALES from "@/i18n/locales";
import NotMatch from "@pages/404";
import zhCN from 'antd/lib/locale/zh_CN';

function App() {

  /**
   * 动态导入antd语言包
   * @param key
   * @return {Promise<*>}
   */
  const importAntdLocale = async (key) => {
    // return await import(`antd/lib/locale/${key}`)
  }

  const currentLocal = useRef(baseStore.language);
  const [local, setLocal] = useState(zhCN);
  const [finished, setFinish] = useState(false);

  useEffect(() => {
    baseStore.getWebInfo();

    const str = localStorage.getItem("local");
    if (str) {
      const local = JSON.parse(str);
      baseStore.language = local.code;
      baseStore.languageId = local.id;
    }
    setTimeout(() => {
      setFinish(true);
    }, 10)
  }, [])

  useEffect(() => {
    if (currentLocal.current === baseStore.language) {
      return
    }
    if (baseStore.language === LOCALES.CHINESE) {
      setLocal(zhCN)
    } else {
      importAntdLocale(baseStore.language).then(loc => {
        setLocal(loc)
      });
    }
  }, [baseStore.language])

  return (
    <IntlProvider locale={baseStore.language.replace("_", "-")} defaultLocale={LOCALES.CHINESE.replace("_", "-")}
                  messages={messages[baseStore.language]}>
      {
        finished
          ? <>
            <Header/>
            <Routes>
              <Route exact={true} path="/" element={<Index/>}/>
              <Route path="/aboutus" element={<AboutUs/>}/>
              <Route path="/product" element={<Product/>}/>
              <Route path="/news" element={<News/>}/>
              <Route path="/contactus" element={<ContactUs/>}/>
              {/*<Route path="joinus" element={<JoinUs/>}/>*/}
              <Route path="/newscontent/:id/:rid" element={<NewsDetail/>}/>
              <Route path="/productcontent/:id/:rid" element={<ProductDetail/>}/>
              <Route path="*" element={<NotMatch/>}/>
            </Routes>
            <Footer/>
          </>
          : null
      }
    </IntlProvider>
  );
}

export default observer(App);
