import React, {useEffect, useRef, useState} from 'react';
import {request} from "@utils/network";
import LazyLoad from 'react-lazyload';
import {Link} from "react-router-dom";
import {Breadcrumb, Pagination} from "antd";
import {FMsg} from "@components/formatted";
import baseStore from "@store/base";
import {observer} from "mobx-react";

function Index(props) {

  const [types, setTypes] = useState([]);
  const [list, setList] = useState([]);
  const [active, setActive] = useState("");
  const [pagination, setPagination] = useState({current: 0, pageSize: 9, total: 0});

  const getList = (page = 0, pageSize = 9, typeId) => {
    request("yqf/productList", {page, pageSize, typeId}).then(res => {
      setList(res.data.list || [])
      setPagination({
        ...pagination,
        total: res.data.total
      })
    })
  }

  const getTypes = () => {
    request("yqf/productType", {}).then(res => {
      const arr = res.data || [];
      setTypes(arr);
      setActive(res.data[0].realTypeId)
    });
  }

  useEffect(() => {
    getTypes()
  }, [baseStore.language])

  useEffect(() => {
    getList(0, 9, active);
    getTypes()
  }, [])

  useEffect(() => {
    getList(pagination.current, pagination.pageSize, active);
  }, [active])

  const changeFilter = item => {
    setActive(item.realTypeId);
  }

  const onPageChange = (page, pageSize) => {
    setPagination({
      current: page - 1,
      pageSize
    })
    getList(page, pageSize);
  }

  return (
    <div className="main">
      <div className="left_right">
        <div className="left_box">
          <span className="left_tit"><i><FMsg id="index.middle.pt1" /></i></span>
          <div className="link">
            {
              types.map(v => (
                <a
                  className={active === v.realTypeId ? "js-bgcolor" : ""}
                  key={v.id}
                  onClick={() => changeFilter(v)}
                >
                  {v.productTypeName}
                </a>
              ))
            }
          </div>
          <span className="left_tit"><i><FMsg id="about.base.contact"/></i></span>
          <ul className="list">
            <li><span><FMsg id="about.base.address"/>:</span><em>{baseStore.currentInfo.address || "-"}</em></li>
            <li><span><FMsg id="about.base.tel"/>:</span><em>{baseStore.currentInfo.hottel || "-"}</em></li>
            <li><span><FMsg id="about.base.fax"/>:</span><em>{baseStore.currentInfo.cz || "-"}</em></li>
            <li><span><FMsg id="about.base.email"/>:</span><em>{baseStore.currentInfo.email || "-"}</em></li>
          </ul>
        </div>
        <div className="right_box">
          <div className="crumb_container">
            <Breadcrumb>
              <Breadcrumb.Item href="/"><FMsg id="index.bar.home"/></Breadcrumb.Item>
              <Breadcrumb.Item><FMsg id="index.bar.product"/></Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <ul className="product_ul jstabs">
            {
              list.map((value, index) => (
                <li key={index}>
                  <Link to={`/productcontent/${value.id}/${value.realProductId}`} title={value.productName}>
                    <LazyLoad>
                      <img className="index_pro_imgl lazy" src={value.productImg} alt=""/>
                    </LazyLoad>
                    <p>{value.productName}</p>
                  </Link>
                </li>
              ))
            }
          </ul>
          <div
            style={{
              textAlign: "center",
              padding: "15px 0"
            }}
          >
            <Pagination
              current={pagination.current + 1}
              pageSize={pagination.pageSize}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);