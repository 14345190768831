import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {FMsg} from "@components/formatted";
import moment from "moment";
import baseStore from "@store/base";
import {observer} from "mobx-react";
import LOCALES from "@/i18n/locales";

function Index(props) {

  const [show, setStatus] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    setInfo({...baseStore.currentInfo})
  }, [baseStore.currentInfo]);

  const onScroll = ev => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setStatus(scrollTop > 900)
  }

  const toTop = () => {
    $('html,body').animate({'scrollTop': '0'})
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  })

  return (
    <div>
      <footer>
        <div className="foot">
          <div className="foot_nav">
            <div className="nav_inline">
              <h2><FMsg id="index.bar.about"/></h2>
              <ul className="foot_ul">
                <li><Link to="/aboutus"><FMsg id="index.footer.profile"/></Link></li>
                <li><Link to="/aboutus"><FMsg id="index.footer.culture"/></Link></li>
                <li><Link to="/aboutus"><FMsg id="index.footer.contact"/></Link></li>
              </ul>
            </div>
            <div className="nav_inline">
              <h2><FMsg id="index.bar.news"/></h2>
              <ul className="foot_ul">
                <li><Link to="/news"><FMsg id="index.footer.news"/></Link></li>
                <li><Link to="/news"><FMsg id="index.footer.industry"/></Link></li>
              </ul>
            </div>
            <div className="nav_inline">
              <h2><FMsg id="index.bar.product"/></h2>
              <ul className="foot_ul">
                <li><Link to="/product"><FMsg id="index.product.painting"/></Link></li>
                <li><Link to="/product"><FMsg id="index.product.iron"/></Link></li>
                <li><Link to="/product"><FMsg id="index.product.smart"/></Link></li>
              </ul>
            </div>
            {/*<div className="nav_inline">*/}
            {/*    <h2>加入我们</h2>*/}
            {/*    <ul className="foot_ul">*/}
            {/*        <li><Link to="/joinus">诚聘英才</Link></li>*/}
            {/*        <li><Link to="/joinus">在线反馈</Link></li>*/}
            {/*        <li><Link to="/joinus">在线留言</Link></li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
          </div>

          <div className="ba">
            &copy;<b id="years">{moment().format("YYYY")}</b>&emsp;
            <FMsg id="index.name" values={{name: `${info.name}` || ""}}/>
            {info.website ? `（${info.website}）` : ""}&emsp;
            <a style={{display: 'inline'}} href="https://beian.miit.gov.cn"><FMsg id="index.record.name"/>：{info.icp || ""}</a>
          </div>
        </div>
      </footer>
      {
        show
          ? <ul className="silder">
            <li>
              <a href="#"><span className="layui-icon layui-icon-login-wechat t1"/></a>
              <div className="scanCode">
                {/*<img src={codeImg} alt="二维码"/>*/}
              </div>
            </li>
            <li><a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=185660614&site=qq&menu=yes"
                   rel="noreferrer"><span className="layui-icon layui-icon-login-qq t2"/></a></li>
            <li><a href="mailto:shenjianguo@nbyqf.cn?subject=通过官网发起邮件"><span className="email t3"/></a></li>
            <li><a href="#" onClick={toTop} id="toTop"><span className="layui-icon layui-icon-top t4"/></a></li>
          </ul>
          : null
      }
    </div>
  );
}

export default observer(Index);
