import React, {useEffect} from 'react';
import {FMsg} from "@components/formatted";
import baseStore from "@store/base";
import {observer} from "mobx-react";

function Index(props) {

  useEffect(() => {
    const map = new BMap.Map('baiduMap');
    const point = new BMap.Point(121.544046, 29.948561);
    map.addControl(new BMap.NavigationControl());//放大缩小控件
    map.centerAndZoom(point, 15);//中心位置的地图缩放级别
    map.enableScrollWheelZoom(true);//开启鼠标滚轮缩放
    const marker = new BMap.Marker(point);        // 创建标注
    map.addOverlay(marker);                     // 将标注添加到地图中
    const opts = {
      width: 200,     // 信息窗口宽度
      height: 30,     // 信息窗口高度
      title: "宁波亿企发科技有限公司",  // 信息窗口标题
      offset: new BMap.Size(-2, -18)
    };
    var infoBox = new BMap.InfoWindow('浙江省宁波市江北区庄桥工业园区', opts);  // 创建信息窗口对象
    map.openInfoWindow(infoBox, map.getCenter());
    window.setTimeout(function () {
      map.panTo(new BMap.Point(121.544046, 29.948561));
    }, 1500);
  }, [])

  return (
    <div className="main">
      <div className="cus">
        <div className="layui-row boxmargin">
          <fieldset className="layui-elem-field layui-field-title">
            <legend><FMsg id="index.footer.contact" /></legend>
          </fieldset>
        </div>
        <div className="layui-row boxmargin flexset" style={{display: "block"}}>
          <ul className="contact">
            <li><FMsg id="about.base.address"/>：{baseStore.currentInfo.address || "-"}</li>
            <li><FMsg id="about.base.tel"/>：{baseStore.currentInfo.hottel || "-"}</li>
            <li><FMsg id="about.base.email"/>：{baseStore.currentInfo.cz || "-"}</li>
            <li><FMsg id="about.base.fax"/>：{baseStore.currentInfo.email || "-"}</li>
          </ul>
          <div className="baiduMap" id="baiduMap"/>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);