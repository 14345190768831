import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {request} from "@utils/network";
import {Breadcrumb, Pagination} from "antd";
import viewImg from "@assets/img/view.png";
import {FMsg} from "@components/formatted";
import baseStore from "@store/base";
import {observer} from "mobx-react";
import {equals} from "ramda";

function Index(props) {

  const dep = useRef({});

  const [newsArr, setNews] = useState([]);
  const [pagination, setPagination] = useState({current: 0, pageSize: 10, total: 0});

  const queryNewsList = (page = 0, pageSize = 10) => {
    request("yqf/newList", {page, pageSize}).then(res => {
      setNews(res.data.list || [])
    })
  }

  useEffect(() => {
    queryNewsList();
    dep.current = {language: baseStore.language};
  }, []);

  useEffect(() => {
    if (!equals(dep.current, {language: baseStore.language})) {
      queryNewsList(pagination.page, pagination.pageSize);
      dep.current = {language: baseStore.language};
    }
  }, [baseStore.language])

  const onPageChange = (page, pageSize) => {
    setPagination({
      current: page - 1,
      pageSize
    })
    queryNewsList(page, pageSize);
  }

  return (
    <div className="main">
      <div className="left_right">
        <div className="left_box">
          <span className="left_tit"><i><FMsg id="index.middle.nt1" /></i></span>
          <div className="link">
            <a className="jsColo"><FMsg id="index.footer.news" /></a>
          </div>
          <span className="left_tit"><i><FMsg id="about.base.contact" /></i></span>
          <ul className="list">
            <li><span><FMsg id="about.base.address" />:</span><em>{baseStore.currentInfo.address || "-"}</em></li>
            <li><span><FMsg id="about.base.tel" />:</span><em>{baseStore.currentInfo.hottel || "-"}</em></li>
            <li><span><FMsg id="about.base.fax" />:</span><em>{baseStore.currentInfo.cz || "-"}</em></li>
            <li><span><FMsg id="about.base.email" />:</span><em>{baseStore.currentInfo.email || "-"}</em></li>
          </ul>
        </div>
        <div className="right_box">
          <div className="crumb_container">
            <Breadcrumb>
              <Breadcrumb.Item href="/"><FMsg id="index.bar.home"/></Breadcrumb.Item>
              <Breadcrumb.Item href="/news"><FMsg id="index.bar.news"/></Breadcrumb.Item>
              <Breadcrumb.Item><FMsg id="index.footer.news"/></Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="news_list_n">
            <ul className="news_ul">
              {
                newsArr.map((value, index) => (
                  <li key={index}>
                    <Link to={`/newscontent/${value.id}/${value.realNewsId}`}>
                      {value.newsName}
                    </Link>
                    <span className="news_cont1">
                      {value.newsContent.replace(/[^\u4e00-\u9fa5]+/g, '')}
                    </span>
                    <div className="index_news_time">
                      {new Date(value.newsCreatetime).toLocaleDateString()}
                      <img src={viewImg} alt="views"/>
                      <em>{value.views}</em>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
          <div
            style={{
              textAlign: "center",
              padding: "15px 0"
            }}
          >
            <Pagination
              current={pagination.current + 1}
              pageSize={pagination.pageSize}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Index);