import React, {useEffect, useRef, useState} from 'react';
import img1 from "@assets/img/16.png";
import img2 from "@assets/img/500.png";
import img3 from "@assets/img/300.png";
import imgT1 from "@assets/img/t1.png";
import imgT2 from "@assets/img/t2.png";
import imgT3 from "@assets/img/t3.png";
import {Link} from "react-router-dom";
import {request} from "@utils/network";
import LazyLoad from 'react-lazyload';
import viewImg from "@assets/img/view.png";
import {observer} from "mobx-react";
import baseStore from "@store/base";
import {FMsg} from "@components/formatted";

function Index(props) {

  const [types, setTypes] = useState([]);
  const [list, setList] = useState([]);
  const [active, setActive] = useState("");
  const [newsArr, setNews] = useState([]);

  useEffect(() => {
    // bounty.default({el: '.odo1', value: '16'});
    // bounty.default({el: '.odo2', value: '500'});
    // bounty.default({el: '.odo3', value: '300'});
  }, [])

  const getProdList = (page = 0, pageSize = 8, typeId) => {
    request("yqf/productList", {page, pageSize, typeId}).then(res => {
      if (res.code === 1) {
        setList(res.data.list || [])
      }
    })
  }

  const getData = () => {
    request("yqf/productType").then(res => {
      const arr = res.data || [];
      arr.unshift({
        realTypeId: "",
        productTypeName: "全部"
      })
      setTypes(arr)
    });
    request("yqf/newList", {page: 0, pageSize: 8}).then(res => {
      setNews(res.data.list || [])
    })
  }

  useEffect(() => {
    getData();
    getProdList()
  }, [])

  useEffect(() => {
    getData();
    getProdList()
  }, [baseStore.languageId])

  const changeFilter = item => {
    setActive(item.realTypeId);
    getProdList(0, 8, item.realTypeId)
  }

  return (
    <div>
      <section className="histroy">
        <ul className="histroy_ul">
          <li>
            <h2><FMsg id="index.middle.year" /></h2>
            <div className="counter"><span className="num">16</span><sub className="sub"><FMsg id="index.middle.childTit1" /></sub></div>
            <img src={img1} alt="16"/></li>
          <li>
            <h2><FMsg id="index.middle.employee" /></h2>
            <div className="counter"><span className="num">500</span><sub className="sub"><FMsg id="index.middle.childTit2" /></sub></div>
            <img src={img2} alt="500"/></li>
          <li>
            <h2><FMsg id="index.middle.served" /></h2>
            <div className="counter"><span className="num">300</span><sub className="sub"><FMsg id="index.middle.childTit3" /></sub></div>
            <img src={img3} alt="300"/></li>
          <p className="layui-clear"/>
        </ul>
      </section>
      <section className="other">
        <div className="other_box">
          <h1><FMsg id="index.middle.h1" /></h1>
          <ul className="other_ul">
            <li>
              <div className="other_left">
                <div className="special_tit">
                  <h1>01</h1>
                  <p><FMsg id="index.middle.t1" /></p>
                </div>
                <span><FMsg id="index.middle.st1" /></span></div>
              <img src={imgT1} alt=""/></li>
            <li>
              <img src={imgT2} alt=""/>
              <div className="other_left">
                <div className="special_tit">
                  <h1>02</h1>
                  <p><FMsg id="index.middle.t2" /></p>
                </div>
                <span><FMsg id="index.middle.st2" /></span></div>
            </li>
            <li>
              <div className="other_left">
                <div className="special_tit">
                  <h1>03</h1>
                  <p><FMsg id="index.middle.t3" /></p>
                </div>
                <span><FMsg id="index.middle.st3" /></span></div>
              <img src={imgT3} alt=""/></li>
          </ul>
        </div>
      </section>
      <section className="index_pro">
        <div className="index_pro_box">
          <h1><FMsg id="index.middle.pt1" /></h1>
          <p><FMsg id="index.middle.pt2" /></p>
          <div className="pro_type_link">
            {
              types.slice(0, 5).map((v, idx) => (
                <span
                  className={active === v.realTypeId ? "js-bgcolor" : ""}
                  key={idx}
                  onClick={() => changeFilter(v)}
                >
                    {v.productTypeName === "全部" ? <FMsg id="index.middle.all"/> : v.productTypeName}
                </span>
              ))
            }
          </div>
          <ul className="index_pro_ul">
            {
              list.map((value, index) => (
                <li key={index}>
                  <Link to={`/productcontent/${value.id}/${value.realProductId}`} title={value.productName}>
                    <LazyLoad>
                      <img className="index_pro_imgl lazy" src={value.productImg} alt=""/>
                    </LazyLoad>
                    <p>{value.productName}</p>
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </section>
      <section className="index_news">
        <div className="index_news_box">
          <h1><FMsg id="index.middle.nt1" /></h1>
          <p><FMsg id="index.middle.nt2" /></p>
          <ul className="index_news_ul">
            {
              newsArr.map((value, index) => (
                <li key={index}>
                  <span className="index_news_img" style={{background: `url(${value.newsSnt}) no-repeat center / cover`}}/>
                  <div className="index_news_right">
                    <Link to={`/newscontent/${value.id}/${value.realNewsId}`} title={value.newsName}>
                      <h1>{value.newsName}</h1>
                    </Link>
                    <span
                      className="index_news_info">{value.newsContent.replace(/[^\u4e00-\u9fa5]+/g, '').substr(0, 50)}</span>
                    <div className="index_news_time">
                      <i>{new Date(value.newsCreatetime).toLocaleDateString()}</i>
                      <img src={viewImg} alt="eyes"/>
                      <em>{value.views}</em>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
          <Link to="/news" className="read_more"><FMsg id="index.viewMore" /></Link>
        </div>
      </section>
    </div>
  );
}

export default observer(Index);