import 'whatwg-fetch';
import qs from "query-string";
import baseStore from "@store/base";
import axios from "axios";

export function request(url, params = {}, isGet = true) {
  return new Promise((resolve, reject) => {
    const baseParams = {
      languageId: baseStore.languageId,
      ...params
    }
    const queryParams = qs.stringify(baseParams);
    const uri = isGet
      ? `${url}${queryParams ? `?${queryParams}` : ""}`
      : url;
    const opt = {
      method: isGet ? "GET" : "POST",
      headers: {
        'Content-Type': "application/json;charset=utf-8",
      },
      // mode: "no-cors"
    }
    if (!isGet) {
      Object.assign(opt, {body: JSON.stringify(baseParams)})
    }
    const host = process.env.NODE_ENV === "development" ? "https://api.199608.vip" : "https://api.199608.vip";
    fetch(`${host}/${uri}`, opt)
      .then(async response => {
        console.log(response)
        return isGet ? await response.text() : response.json()
      }).then(res => {
      resolve(JSON.parse(res))
    }).catch(err => {
      console.log("请求失败：", err)
      reject(err)
    })

    // const method = isGet ? "GET" : "POST";

    // const cfg = {
    //   url: `${host}/${uri}`,
    //   method,
    //   headers: {
    //     'Content-Type': "application/json;charset=utf-8",
    //   },
    //   ...opt,
    //   ...params,
    // };
    //
    // axios({
    //   ...cfg,
    // }).then(result => {
    //   const res = result.data || {};
    //   resolve(res)
    // }).catch(error => {
    //   reject(error)
    // }).finally(() => {
    //
    // });

  })
}