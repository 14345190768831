import React, {useEffect, useRef, useState} from 'react';
import {request} from "@utils/network";
import baseStore from "@store/base";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import "./index.less";
import {FMsg} from "@components/formatted";

function NotMatch(props) {

  const dep = useRef(baseStore.language);
  const [list, setList] = useState([]);

  const getData = () => {
    request("yqf/randomProductList", {ranNum: 6}).then(res => {
      setList([...(res.data.list || [])])
    })
  }

  useEffect(() => {
    getData()
    dep.current = baseStore.language;
  }, [])

  useEffect(() => {
    if (dep.current !== baseStore.language) {
      getData();
      dep.current = baseStore.language;
    }
  }, [baseStore.language])

  return (
    <div className='not_match_page'>
      <b className="msg"><FMsg id="page.notMatch"/>。。。</b>
      <section className="rec_section">
        <b><FMsg id="page.notMatch.recommend"/></b>
        <ul className="recommend_product_view">
          {
            list.map((value, index) => (
              <li key={index}>
                <div className="item_wrap">
                  <Link to={`/productcontent/${value.id}/${value.realProductId}`}>
                    <span className="index_pro_imgl ov-he"
                          style={{background: `url(${value.productImg}) no-repeat center / cover`}}/>
                    <p>{value.productName}</p>
                  </Link>
                </div>
              </li>
            ))
          }
        </ul>
      </section>
    </div>
  );
}

export default observer(NotMatch);